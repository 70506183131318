import { createSlice } from '@reduxjs/toolkit';

/* Import layout constants */
import * as layoutConstants from '../../constants/layout';

/* Set up initial state */
const initialLayoutState = {
    layoutColor: layoutConstants.LAYOUT_COLOR_LIGHT,
    layoutType: layoutConstants.LAYOUT_VERTICAL,
    layoutWidth: layoutConstants.LAYOUT_WIDTH_FLUID,
    leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_LIGHT,
    leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_FIXED,
    showRightSidebar: false,
};

/* Set up slice */
const layoutSlice = createSlice({
    name: 'layout',
    initialState: initialLayoutState,
    reducers: {
        changeSideBarType(state, action) {
            state.leftSideBarType = action.payload;
        }
    }
});

export const layoutActions = layoutSlice.actions;
export default layoutSlice;