/* Package imports */
import Routes from './routes/Routes';

/* Component imports */
import FeedbackModal from './components/FeedbackModal';

/* Style imports */
import './assets/scss/Saas.scss';


const App = (props) => {
  return (
    <>
      <FeedbackModal></FeedbackModal>
      <Routes></Routes>
    </>
  );
};

export default App;
