/* Package imports */
import React from 'react';
import ReactDOM from 'react-dom/client';

/* Component imports */
import App from './App';

/* Utility imports */
import { Provider } from 'react-redux';
import store from './store/index';

/* For web vital reporting */
import reportWebVitals from './reportWebVitals';


/*
  - Important: As of newer React versions, running strict mode in development will render each component twice. This does not happen in production.
*/
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

/* If you want to start measuring performance in your app, pass a function to log results (for example: reportWebVitals(console.log)) or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals */
// reportWebVitals(console.log);
