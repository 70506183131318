/* Package imports */
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

/* Utility imports */
import { findArrayIntersection } from '../constants/global';


/*
    - Private Route forces the authorization before the route can be accessed
*/
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }) => {

    let location = useLocation();
    
    /* Reach out to redux store for auth data */
    const auth = useSelector((state) => {
        return state.auth;
    });

    /* Not logged in so redirect to login page with the return url */
    if (auth.token === null) {
        return <Navigate to={'/account/login'} state={{ from: location }} replace />;
    }

    /* Check if the route is restricted by role */
    if (roles) {
        // If so, does the role exist for the user
        if(findArrayIntersection(auth.userRoles, roles).length === 0) {
            // Role not authorised so redirect to home page
            return <Navigate to={{ pathname: '/' }} />;
        }
    }

    return <RouteComponent />;
};

export default PrivateRoute;
