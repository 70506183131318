/* Package imports */
import { useSelector, useDispatch } from 'react-redux';

/* Component imports */
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";

/* Utility imports */
import { globalFeedbackActions } from '../store/globalFeedback/globalFeedback';

/* Style imports */
import appStyles from '../App.module.css';


const FeedbackModal = (props) => {

    const globalFeedback = useSelector((state) => {
        return state.globalFeedback;
    });
    
    const dispatch = useDispatch();
    
    const handleClose = () => {
        dispatch(globalFeedbackActions.hideFeedback());
    };

    return(
        <Modal
            show={globalFeedback.show}
            onHide={handleClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {globalFeedback.type === 'error' ? <span className={appStyles.dangerText}>Something went wrong.</span> : <span className={appStyles.successText}>Success!</span> }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {globalFeedback.message}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} variant={globalFeedback.type === 'error' ? 'danger' : 'success'}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FeedbackModal;